<template>
  <div class="image-editor">
    <div style="position: absolute; top: 15px; left: 15px; z-index: 999">
      <div class="btn-content" style="display: flex; flex-direction: column; align-items: center">
        <img width="54px" src="~@/assets/log-it-logo-opacity.png" />
        <span class="font-weight-bold" style="font-size: 17px; color: #ccc">Log-It</span>
      </div>
    </div>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn @click="mode = 'comment'" tile class="btn-editor mr-2" :class="{ active: mode == 'comment' }">
        <v-icon class="mr-2">mdi-comment-text</v-icon>Comment
      </v-btn>
      <v-btn @click="mode = 'draw'" tile class="btn-editor" :class="{ active: mode == 'draw' }">
        <v-icon class="mr-2">mdi-draw</v-icon>
        <div>Pen</div>
      </v-btn>
      <!-- <v-menu v-if="mode == 'draw'" offset-y z-index="100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            tile
            small
            dark
            icon
            v-bind="attrs"
            v-on="on"
            class="btn-editor"
            :class="{ active: mode == 'draw' }"
            style="border-left: 0"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title
              class="btn-tool"
              @click="(tool = 'pen'), (isErasing = false)"
              :class="{ active: !isErasing }"
              ><v-icon small class="mr-2">mdi-pencil</v-icon>Pen</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              class="btn-tool"
              @click="(tool = 'pen'), (isErasing = false)"
              :class="{ active: !isErasing }"
              ><v-icon small class="mr-2">mdi-pencil</v-icon>Pen</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              class="btn-tool"
              @click="(tool = 'eraser'), (isErasing = true)"
              :class="{ active: isErasing }"
              ><v-icon small class="mr-2">mdi-eraser</v-icon>Eraser</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- <div v-if="mode == 'draw'">
        <v-btn small class="btn-tool mr-4" @click="(tool = 'pen'), (isErasing = false)" :class="{ active: !isErasing }"
          ><v-icon small class="mr-2">mdi-pencil</v-icon>Pen</v-btn
        >
        <v-btn small class="btn-tool" @click="(tool = 'eraser'), (isErasing = true)" :class="{ active: isErasing }"
          ><v-icon small class="mr-2">mdi-eraser</v-icon>Eraser</v-btn
        >
      </div> -->
    </v-toolbar>
    <v-toolbar v-if="mode == 'draw'" class="tools" flat dense height="50">
      <v-spacer></v-spacer>

      <v-tooltip bottom z-index="100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-2 btn-tool"
            @click="(tool = 'pen'), (isErasing = false)"
            tile
            icon
            :class="{ active: tool == 'pen' }"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
        <span>Pen</span>
      </v-tooltip>

      <v-btn
        class="mr-2 btn-tool"
        @click="(tool = 'marker'), (isErasing = false)"
        tile
        icon
        :class="{ active: tool == 'marker' }"
      >
        <v-icon>mdi-marker</v-icon>
      </v-btn>
      <v-btn
        class="btn-tool"
        @click="(tool = 'eraser'), (isErasing = false)"
        tile
        icon
        :class="{ active: tool == 'eraser' }"
      >
        <v-icon>mdi-eraser</v-icon>
      </v-btn>
      <div
        class="divider mr-2 ml-2"
        style="height: 30px; width: 2px; background-color: #aaa; margin: 0 3px 0 3px"
      ></div>
      <!-- <v-btn
        class="btn-tool"
        @click="showColorPicker = !showColorPicker"
        icon
        :class="{ active: tool == 'color-select' }"
      >
        <v-icon :color="pickerColor">mdi-circle-slice-8</v-icon>
      </v-btn> -->

      <v-menu
        style="z-index: 101"
        ref="menu-color-picker"
        v-model="showColorPicker"
        :close-on-content-click="false"
        transition="slide-x-reverse-transition"
        min-width="auto"
        :nudge-left="256"
        :nudge-bottom="44"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="btn-tool" icon :class="{ active: tool == 'color-select' }">
            <v-icon :color="pickerColor">mdi-circle-slice-8</v-icon>
          </v-btn>
        </template>

        <v-color-picker
          v-if="showColorPicker"
          class="color-picker"
          dot-size="25"
          swatches-max-height="200"
          mode="hexa"
          v-model="pickerColor"
        ></v-color-picker>
      </v-menu>
    </v-toolbar>

    <v-toolbar v-if="mode == 'comment'" class="comment-nav" flat dense height="50"
      ><v-spacer></v-spacer>
      <v-btn small to="#" class="btn-editor mr-2" :class="{ active: mode == 'comment' }">Log</v-btn>
      <v-btn small to="#" class="btn-editor mr-2">Activity</v-btn>
      <v-btn small to="#" class="btn-editor">History</v-btn>
    </v-toolbar>

    <!-- <div class="toolbar mb-4">
      <v-btn @click="mode = 'draw'" outlined class="btn-editor mr-4" :class="{ active: mode == 'draw' }"
        ><v-icon class="mr-2">mdi-pencil</v-icon>Draw</v-btn
      >

      <v-btn @click="mode = 'comment'" outlined class="mr-4 btn-editor" :class="{ active: mode == 'comment' }"
        ><v-icon class="mr-2">mdi-comment-text</v-icon>Comment</v-btn
      >
    </div>
    <div style="height: 30px" class="toolbar ma-4">
      <div v-if="mode == 'draw'">
        <v-btn small class="btn-tool mr-4" @click="(tool = 'pen'), (isErasing = false)" :class="{ active: !isErasing }"
          ><v-icon small class="mr-2">mdi-pencil</v-icon>Pen</v-btn
        >
        <v-btn small class="btn-tool" @click="(tool = 'eraser'), (isErasing = true)" :class="{ active: isErasing }"
          ><v-icon small class="mr-2">mdi-eraser</v-icon>Eraser</v-btn
        >
      </div>
    </div> -->

    <div
      class="canvas-wrap"
      id="canvasWrap"
      ref="canvasWrap"
      :class="mode + ' ' + tool"
      :width="canvasWidth"
      :height="canvasHeight"
    >
      <canvas
        class="canvas draw"
        ref="canvasDraw"
        :width="canvasWidth"
        :height="canvasHeight"
        @touchstart="handleEvent"
        @touchmove="handleEvent"
        @touchend="handleEvent"
        @mousedown="handleEvent"
        @mousemove="handleEvent"
        @mouseup="handleEvent"
        @mouseout="handleEvent"
        @dragover.prevent="onDragOver"
        @drop.prevent="onDrop"
        @click="handleEvent"
      ></canvas>

      <canvas class="canvas photo" ref="canvasPhoto" :width="canvasWidth" :height="canvasHeight"></canvas>
      <!-- :style="{ zIndex: mode == 'draw' ? 30 : 20 }"
      zIndex: mode == 'comment' ? 30 : 20, -->
      <div
        class="comments-wrap"
        :style="{
          width: canvasWidth + 'px',
          height: canvasHeight + 'px',
        }"
      >
        <div v-for="(comment, index) in comments" :key="index">
          <div
            class="comment-wrap"
            :class="currentComment != null ? 'active' : ''"
            :style="{ top: comment.y + '%', left: comment.x + '%' }"
          >
            <!-- :style="dragging[index] ? dragCommentStyle : ''" -->
            <div class="comment" :id="'comment-' + index">
              <div
                class="trigger-show-comment"
                :id="'triggerShowComment_' + index"
                :class="{ 'show-move': comment.showDetails }"
                @mousedown="triggerComment($event, comment, index)"
                @touchstart="triggerComment($event, comment, index)"
                @click="!isDragging ? showComment(index) : null"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50">
                  <circle class="large-circle" cx="25" cy="25" r="18" fill="#fbecec" />
                  1
                  <circle cx="25" cy="25" r="12" :fill="commentColor(comment.severity)" />
                  <text x="25" y="30" font-family="Arial" font-size="16" text-anchor="middle" fill="white">
                    {{ index + 1 }}
                  </text>
                </svg>
                <div class="edit-connect" v-if="comment.showSummary || comment.showDetails"></div>
              </div>

              <div
                :style="{
                  left: `calc(${-comment.x}% + 25px)`,
                  top: '15px',
                  width: canvasWidth - 30 + 'px',
                }"
                :id="'editComment_' + index"
                class="edit-comment"
                v-if="comment.showSummary || comment.showDetails"
              >
                <!-- <div
                  class="edit-remove"
                  @click="hideComment(index)"
                  style="
                    cursor: pointer;
                    position: absolute;
                    right: -17px;
                    top: -18px;
                    background: #211f1f;
                    padding: 5px;
                    border-radius: 100%;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    font-weight: bold;
                    font-family: 'Nunito';
                  "
                >
                  X
                </div> -->

                <div class="d-flex align-center">
                  <span class="mr-2 mt-0 pt-0 pl-2 overline">Log-It</span>
                  <v-spacer></v-spacer>
                  <v-icon style="position: relative; right: 0; top: -4px" large :color="commentColor(comment.severity)">
                    mdi-fire
                  </v-icon>
                </div>

                <v-textarea
                  dense
                  filled
                  auto-grow
                  rows="1"
                  row-height="15"
                  hide-details="true"
                  class="textarea"
                  v-model="comment.comment"
                ></v-textarea>
                <v-slider
                  class="mt-2"
                  v-model="comment.severity"
                  :tick-labels="ticksLabels"
                  :color="commentColor(comment.severity)"
                  max="2"
                  step="1"
                  ticks="always"
                  tick-size="4"
                >
                </v-slider>
                <span class="d-flex">
                  <v-btn color="primary" small class="mt-2" @click="submitIssue(comment)">Submit</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="removeComment(index, $event)" x-small class="mt-2">Delete</v-btn>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheImageEditor",

  data() {
    return {
      isDrawing: false,
      isErasing: false,
      isDragging: false,
      lastX: 0,
      lastY: 0,
      img: null,
      ctx: null,
      photoctx: null,
      canvasWidth: 800,
      canvasHeight: 600,
      mode: "draw",
      tool: "pen",
      comments: [],
      //dragging: [],
      offset: { x: 0, y: 0 },
      currentComment: null,
      ticksLabels: ["Store-It", "Review-It", "Escalate-It"],
      pickerColor: "#FF0000",
      showColorPicker: false,
    };
  },
  props: {
    imageBlob: Blob,
    imageFile: String,
  },
  watch: {
    tool(n, o) {
      console.log("Prop changed from", o, "to", n);
    },
  },
  computed: {
    dragCommentStyle() {
      return {
        //cursor: this.dragging ? "grabbing!important" : "grab!important",
        transform: `translate(${this.position.x}px, ${this.position.y}px)`,
      };
    },
    // dragCommentClass() {
    //   return this.dragging[0] ? "grabbing" : "grab";
    // },
  },
  methods: {
    submitIssue(comment) {
      alert("POST: " + JSON.stringify(comment));
    },
    commentColor(severity) {
      if (severity < 1) return "#FFAB00";
      if (severity < 2) return "#E65100";
      if (severity < 3) return "#B71C1C";
      return "red";
    },
    showCommentSummary(index) {
      this.$set(this.comments[index], "showSummary", true);
    },
    hideCommentSummary(index) {
      this.$set(this.comments[index], "showSummary", false);
    },
    showComment(index) {
      if (this.comments[index].showDetails) {
        this.$set(this.comments[index], "showDetails", false);
      } else {
        this.$set(this.comments[index], "showDetails", true);
      }
    },
    hideComment(index) {
      this.$set(this.comments[index], "showDetails", false);
    },
    handleEvent(event) {
      if (this.mode == "draw") {
        switch (event.type) {
          case "touchstart":
            this.startDrawingTouch(event);
            break;
          case "touchmove":
            this.handleTouchMove(event);
            break;
          case "touchend":
            this.stopDrawing(event);
            break;
          case "mousedown":
            this.startDrawingMouse(event);
            break;
          case "mousemove":
            this.drawMouse(event);
            break;
          case "mouseup":
          case "mouseout":
            this.stopDrawing(event);
            break;
        }
      } else if (this.mode == "comment") {
        switch (event.type) {
          case "click":
            if (this.mode === "comment") {
              this.addComment(event);
            }
            break;
        }
      }
    },
    triggerComment(event, comment) {
      event.stopPropagation();
      this.currentComment = comment;
      this.offset.x = this.currentComment.x;
      this.offset.y = this.currentComment.y;
      document.addEventListener("mousemove", this.onCommentDrag);
      document.addEventListener("mouseup", this.endCommentDrag);
      document.addEventListener("touchmove", this.onCommentDrag);
      document.addEventListener("touchend", this.endCommentDrag);
    },
    onCommentDrag(event) {
      if (!this.currentComment) return;

      this.isDragging = true;

      const { xPercent, yPercent } = this.getCoords(event);
      if (xPercent < 1 || xPercent > 99 || yPercent < 1 || yPercent > 99) return;

      const comment = document.getElementById("editComment_" + this.currentComment.index);

      if (xPercent > 70) {
        comment?.classList.toggle("comment-left", true);
      } else {
        comment?.classList.toggle("comment-left", false);
      }

      this.currentComment.x = xPercent;
      this.currentComment.y = yPercent;
    },
    endCommentDrag() {
      document.removeEventListener("mousemove", this.onCommentDrag);
      document.removeEventListener("mouseup", this.endCommentDrag);
      document.removeEventListener("touchmove", this.onCommentDrag);
      document.removeEventListener("touchend", this.endCommentDrag);
      this.$set(this.comments[this.currentComment.index], "x", this.currentComment.x);
      this.$set(this.comments[this.currentComment.index], "y", this.currentComment.y);
      this.currentComment = null;
      this.offset = { x: 0, y: 0 };
      setTimeout(() => {
        this.isDragging = false;
      }, 100);
    },
    addComment(event) {
      console.log("add comment");
      const { xPercent, yPercent } = this.getCoords(event);
      this.comments.push({
        index: this.comments.length,
        x: xPercent,
        y: yPercent,
        comment: "",
        resolved: "",
        severity: 1,
        showDetails: true,
      });
    },
    getCoords(event) {
      const canvas = this.$refs.canvasPhoto;
      const rect = canvas.getBoundingClientRect();
      let x, y;

      if (event.touches && event.touches.length > 0) {
        x = event.touches[0].clientX - rect.left;
        y = event.touches[0].clientY - rect.top;
      } else {
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      }
      return {
        xPercent: (x / rect.width) * 100,
        yPercent: (y / rect.height) * 100,
      };
    },
    removeComment(index, event) {
      event.stopPropagation();
      this.comments.splice(index, 1);
    },
    loadImage() {
      const canvasDraw = this.$refs.canvasDraw;
      this.ctx = canvasDraw.getContext("2d");
      const canvasPhoto = this.$refs.canvasPhoto;
      this.photoctx = canvasPhoto.getContext("2d");
      const url = URL.createObjectURL(this.imageBlob);
      const img = new Image();
      let aspectRatio = img.naturalWidth / img.naturalHeight;
      img.onload = () => {
        aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasWidth = canvasPhoto.offsetWidth;
        this.canvasHeight = this.canvasWidth / aspectRatio;

        this.$nextTick(() => {
          this.photoctx.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
        });
      };

      img.src = url;

      const resizeListener = () => {
        this.canvasWidth = canvasPhoto.offsetWidth;
        this.canvasHeight = this.canvasWidth / aspectRatio;
        this.$nextTick(() => {
          this.photoctx.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
        });
      };

      // Add the event listener
      window.addEventListener("resize", resizeListener);

      this.$once("hook:destroyed", () => {
        window.removeEventListener("resize", resizeListener);
      });
    },
    //draw on image:
    startDrawingTouch(e) {
      console.log("start drawing");
      const rect = this.$refs.canvasDraw.getBoundingClientRect();
      this.isDrawing = true;
      this.lastX = e.touches[0].clientX - rect.left;
      this.lastY = e.touches[0].clientY - rect.top;
    },
    startDrawingMouse(e) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    },
    handleTouchMove(e) {
      e.preventDefault();
      this.drawTouch(e);
    },
    toggleEraser() {
      this.isErasing = !this.isErasing;
    },
    drawMouse(e) {
      if (!this.isDrawing) return;

      if (this.isErasing) {
        this.ctx.globalCompositeOperation = "destination-out"; // Set the eraser mode
        this.ctx.lineWidth = 10; // You can set eraser size
        this.ctx.strokeStyle = "rgba(0,0,0,1)";
      } else {
        this.ctx.globalCompositeOperation = "source-over"; // Set the drawing mode
        this.ctx.lineWidth = 5; // You can set pen size
        this.ctx.strokeStyle = this.pickerColor;
        this.ctx.lineWidth = 5;
      }

      this.ctx.beginPath();
      //this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.moveTo(...this.getPosition(e));
      this.ctx.lineTo(e.offsetX, e.offsetY);
      this.ctx.stroke();
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    },
    drawTouch(e) {
      if (!this.isDrawing) return;
      const rect = this.$refs.canvasDraw.getBoundingClientRect();
      const x = e.touches[0].clientX - rect.left;
      const y = e.touches[0].clientY - rect.top;
      this.ctx.strokeStyle = this.pickerColor;
      this.ctx.lineWidth = 5;

      if (this.isErasing) {
        this.ctx.globalCompositeOperation = "destination-out";
        this.ctx.lineWidth = 10;
        this.ctx.strokeStyle = "rgba(0,0,0,1)";
      } else {
        this.ctx.globalCompositeOperation = "source-over";
        this.ctx.lineWidth = 5;
        this.ctx.strokeStyle = this.pickerColor;
        this.ctx.lineWidth = 5;
      }

      this.ctx.beginPath();
      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
      this.lastX = x;
      this.lastY = y;
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    getPosition(e) {
      const canvas = this.$refs.canvasDraw;
      const rect = canvas.getBoundingClientRect();

      if (e.touches) {
        const touch = e.touches[0];
        return [touch.clientX - rect.left, touch.clientY - rect.top];
      } else {
        return [this.lastX, this.lastY];
        //return [this.lastX, this.lastY];
      }
    },

    onDragStart(event) {
      // Set the drag's format and data. Here we use "text/plain" and the element's ID.
      event.dataTransfer.setData("text/plain", event.target.id);
      console.log(event);
    },
    onDragOver(event) {
      // Prevent default to allow drop
      event.preventDefault();
    },
  },
  mounted() {
    this.loadImage();
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.onCommentDrag);
    document.removeEventListener("mouseup", this.endCommentDrag);
    document.removeEventListener("touchmove", this.onCommentDrag);
    document.removeEventListener("touchend", this.endCommentDrag);
  },
};
</script>

<style lang="scss">
.image-editor {
  // .toolbar {
  //   position: fixed;
  //   top: 66px;
  //   right: 15px;
  //   width: 360px;
  //   border: 1px solid #eee;
  //   border-radius: 5px;
  // }
  .canvas {
    width: 100%;
    height: auto;
  }
  .comment-nav,
  .tools {
    margin-top: -10px;
  }
  .btn-editor {
    //border: 2px solid #4f4f4f;
    border-radius: 3px !important;
    padding: 0 12px !important;
    &.active {
      background: rgba(255, 2555, 255, 0.25);
    }
  }
  .btn-tool {
    border-radius: 3px !important;
    //border: 2px solid #4f4f4f;
    padding: 4px 12px !important;
    height: unset !important;
    min-width: unset !important;
    &.active {
      background: rgba(255, 2555, 255, 0.25);
      //border: 2px solid #ccc;
    }
  }
  .canvas-wrap {
    position: relative;
    border-top: 2px solid #aaa;
    .canvas {
      position: absolute;
      top: 0;
      left: 0;
      &.photo {
        z-index: 10;
      }
      &.draw {
        z-index: 20;
      }
    }
    &.draw {
      cursor: url("~@/assets/pencil.svg") 4 28, auto;
    }
    &.eraser {
      cursor: url("~@/assets/eraser.svg") 4 28, auto;
    }
    &.comment {
      cursor: url("~@/assets/comment-text.svg"), auto;
    }
    .comments-wrap {
      position: relative;
      .comment-wrap {
        margin: -25px 0px 0px -25px;
        position: absolute;
        z-index: 40;
        &.active {
          .trigger-show-comment .large-circle {
            stroke: rgba(250, 246, 177, 0.4);
            stroke-width: 12;
          }
        }
      }
      .trigger-show-comment {
        cursor: pointer;
        touch-action: none;
        width: 36px;
        height: 36px;
        &.show-move {
          cursor: move;
        }
        .edit-connect {
          position: relative;
          left: 22px;
          top: -15px;
          height: 30px;
          width: 5px;
          background: #fff;
        }
      }
      .edit-comment {
        cursor: default;
        position: relative;
        top: 15px;
        left: 0;
        background: #333;
        padding: 8px 15px 15px;
        border: 5px solid #fbecec;
        border-radius: 8px;
        width: 360px;
        &.comment-left {
          left: -280px;
          .edit-connect {
            left: 236px;
          }
        }
        .textarea textarea {
          line-height: 1.3;
          padding-bottom: 10px;
        }
        // .v-slider__track-background {
        //   background-color: transparent !important;
        // }
        .v-slider__track-fill {
          background-color: #5c5c5c !important;
        }
      }
    }
  }
  .image-editor canvas {
    border: 1px solid #ccc;
  }
  @keyframes severity-level {
    from {
      transform: scale(0.5);
    }

    to {
      transform: scale(1);
    }
  }
}

@media (min-width: 768px) {
  .image-editor {
    .canvas-wrap {
      .comments-wrap {
        .edit-comment {
          position: relative;
          // top: -95px !important;
          // left: 60px !important;
          max-width: 420px !important;

          .edit-connect {
            position: relative;
            border-bottom: 5px solid #fbecec;
            left: -45px;
            top: 55px;
            width: 30px;
          }
          &.comment-left {
            left: -280px;
            .edit-connect {
              left: 236px;
            }
          }
        }
      }
    }
  }
}
</style>
