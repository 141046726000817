<template>
  <div id="issue">
    <div v-if="imageBlob">
      <image-editor :imageBlob="imageBlob"></image-editor>
    </div>
    <div v-else>
      <div
        class="text-center"
        style="display: flex; justify-content: center; align-items: center; height: 100vh; margin-top: -50px"
      >
        <div>
          <img src="~@/assets/log-it-logo-opacity.png" style="max-width: 150px" />
          <h2 class="mb-4">Log-It</h2>
          <v-btn class="mt-4" color="primary" v-if="!imageBlob" @click="pickImage">Pick Image</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageEditor from "@/components/TheImageEditor.vue";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

export default {
  name: "Issue",
  metaInfo: {
    title: "Issue",
  },
  components: {
    ImageEditor,
  },
  data: () => ({
    imageBlob: null,
    image: null,
    mode: "draw",
  }),
  methods: {
    async pickImage() {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.Uri,
          source: this.$platform !== "web" ? CameraSource.Prompt : CameraSource.Photos,
        });

        this.image = image.webPath;
        this.imageBlob = null; //reset
        this.imageToBlob(this.image, (blob) => {
          console.log("img to blob");
          console.log(blob);

          this.imageBlob = blob;
          console.log("Blob created:", blob);
        });
      } catch (error) {
        console.error("Error picking images: ", error);
      }
    },

    imageToBlob(imageUrl, callback) {
      // Create an Image object
      const img = new Image();

      // Set up a CORS header (if the image is served from a different origin)
      img.crossOrigin = "Anonymous";

      // Define what happens once the image is loaded
      img.onload = function () {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas dimensions to the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert the canvas content to a Blob
        canvas.toBlob(function (blob) {
          // Execute the callback with the Blob as its argument
          callback(blob);
        }, "image/png"); // You can set the desired output image format (e.g., image/jpeg)
      };

      // Handle errors in loading the image
      img.onerror = function () {
        console.error("There was an error loading the image.");
      };

      // Start loading the image
      img.src = imageUrl;
    },
  },
  mounted() {
    // this.imageToBlob(img, function (blob) {
    //   console.log("img to blob");
    //   console.log(blob);
    //   this.imageBlob = blob;
    //   console.log("Blob created:", blob);
    // });
    //this.imageToBlob((img) => (this.imageBlob = blob));
    //phone - take photo and load and/or pick photo and load
    // this.imageFile = "https://clockworksafety-public.s3.us-west-2.amazonaws.com/dev/unsafe-example.jpg";
    // this.imageToBlob(this.imageFile, (blob) => {
    //   console.log("img to blob");
    //   console.log(blob);
    //   this.imageBlob = blob;
    //   console.log("Blob created:", blob);
    // });
  },
};
</script>
